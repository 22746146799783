<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    *ngIf="!isFullScreen"
    mode="side"
    class="sidenav"
    [opened]="!isCollapsed"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
  >
    <div class="sidenav-header">
      <div class="logo"></div>
      <div *ngIf="!isCollapsed" class="ms-2">{{ 'core.appName' | translate }}</div>
    </div>

    <mat-nav-list class="m-2">

      <!-- navigation -->
      <section *ngFor="let section of sidenavList; let i = index" [attr.id]="'side-nav-section-' + i">

        <!-- group name on collapsed -->
        <mat-list-item
          *ngIf="section.name && !isCollapsed"
          role="listitem"
          class="navigation-entry navigation-entry-group"
          (click)="section.isCollapsed = !section.isCollapsed"
        >
          <span matline>
            {{ translationPrefix + section.name | translate | uppercase }}
          </span>

          <mat-icon
            *ngIf="!section.isCollapsed"
            class="navigation-entry-group-collapse">
            keyboard_arrow_down
          </mat-icon>
          <mat-icon
            *ngIf="section.isCollapsed"
            class="navigation-entry-group-collapse">
            keyboard_arrow_up
          </mat-icon>


        </mat-list-item>

        <!-- hide able menu section -->
        <ng-container *ngIf="!section.isCollapsed">
          <!-- nav item -->
          <a *ngFor="let navItem of section.items"
             routerLink="{{ navItem.route }}"
          >

            <mat-list-item
              routerLinkActive="active"
              [matTooltipDisabled]="!isCollapsed"
              matTooltip="{{ translationPrefix + navItem.name | translate }}"
            >
              <mat-icon
                [ngClass]="{'pe-3': !isCollapsed}"
                matListIcon
              >
                {{ navItem.icon }}
              </mat-icon>

              <span matline class="w-100 navigation-entry navigation-entry-name" *ngIf="!isCollapsed">
                {{ translationPrefix + navItem.name | translate }}
              </span>

            </mat-list-item>
          </a>
        </ng-container>
      </section>
    </mat-nav-list>


    <div class="sidenav-footer">
      <mat-nav-list class="m-2">

        <mat-list-item *ngIf="isCollapsed" (click)="toggleSidenav()">
          <mat-icon
            matListIcon
          >
            keyboard_arrow_right
          </mat-icon>

        </mat-list-item>

        <a
          routerLink="settings">
          <mat-list-item
            routerLinkActive="active"
          >
            <mat-icon
              [ngClass]="{'pe-3': !isCollapsed}"
              matListIcon
            >
              settings_outline
            </mat-icon>

            <span matline class="w-100 navigation-entry navigation-entry-name" *ngIf="!isCollapsed">
                {{ 'core.sidenav.settings' | translate }}
              </span>

            <button *ngIf="!isCollapsed"
                    mat-icon-button
                    (click)="toggleSidenav()"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

          </mat-list-item>
        </a>


      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content autosize [ngClass]="{'sidenav-content': !isFullScreen}" class="h-100">
    <core-toolbar *ngIf="!isFullScreen" [hideBorder]="isTop"></core-toolbar>
    <div #content [ngClass]="{'p-3': !isFullScreen, 'content': !isFullScreen, 'h-100': isFullScreen}" (scroll)="onScroll(content.scrollTop)">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
