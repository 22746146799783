import {createReducer, on} from '@ngrx/store';
import {removeToken} from "../jwt-config";
import {decodeToken, login, loginFail, loginSuccess, logout} from './authentication.actions';
import {initialAuthenticationState} from './authentication.state';

export const authenticationReducer = createReducer(
  initialAuthenticationState,
  on(login, state => ({...state, errorCode: undefined})),
  on(loginSuccess, state => ({...state, isLoggedIn: true})),
  on(loginFail, (state, {errorCode}) => ({...state, errorCode: errorCode})),
  on(decodeToken, (state) => ({...state})),
  on(logout, (state) => {
    removeToken();
    return {isLoggedIn: false, ...state}
  }),
);
