import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";
import {IAuthenticationToken, ICredentials} from '../authentication.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  public login(credentials: ICredentials): Observable<IAuthenticationToken> {
    return this._httpClient.post<IAuthenticationToken>(`${environment.apiBaseUrl}/tokens`, credentials);
  }
}
