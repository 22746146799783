import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from "@angular/material/menu";
import {MatSidenavModule} from '@angular/material/sidenav';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {LoginComponent} from './authentication/login/login.component';
import {AuthenticationEffects} from './authentication/store/authentication.effects';
import {UrlAddAuthPipe} from "./authentication/url-add-auth.pipe";
import {CoreRoutingModule} from './core-routing.module';
import {SidenavComponent} from './navigation/sidenav/sidenav.component';
import {ToolbarComponent} from './navigation/toolbar/toolbar.component';
import {coreReducer} from './store/core.reducer';
import {initialCoreState} from './store/core.state';

@NgModule({
  declarations: [
    LoginComponent,
    SidenavComponent,
    ToolbarComponent,
    UrlAddAuthPipe,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    EffectsModule.forFeature([AuthenticationEffects]),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    ReactiveFormsModule,
    StoreModule.forFeature('core', coreReducer, {initialState: initialCoreState}),
    TranslateModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500, horizontalPosition: 'start'}},
  ],
  exports: [
    SidenavComponent,
  ],
})
export class CoreModule {
}
