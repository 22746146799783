import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ContentFrameService} from "../services/content-frame.service";
import {ISidenavItemGroup} from './sidenav.model';

@Component({
  selector: 'core-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {

  public isTop: boolean = true;

  public sidenavList: ISidenavItemGroup[] = [
    {
      items: [
        {
          name: 'dashboard',
          route: 'dashboard',
          icon: 'dashboard',
        },
      ],
    },
    {
      name: 'certificates',
      items: [
        {
          name: 'templates',
          route: 'certificates/templates',
          icon: 'article',
        },
        {
          name: 'archive',
          route: 'certificates/archive',
          icon: 'inventory_2',
        },
      ],
    },
    {
      name: 'trainings',
      items: [
        {
          name: 'planner',
          route: 'trainings/planner',
          icon: 'event',
        },
        {
          name: 'courses',
          route: 'trainings/courses',
          icon: 'school',
        },
        {
          name: 'participants',
          route: 'trainings/participants',
          icon: 'groups',
        },
      ],
    },
  ];

  public readonly translationPrefix: string = 'core.sidenav.';

  public isCollapsed: boolean = false;

  public isFullScreen: boolean;

  constructor(
    private _contentFrameService: ContentFrameService,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._contentFrameService.isFullScreen.subscribe((isFullScreen: boolean) => {
      this.isFullScreen = isFullScreen;
    });

  }

  public toggleSidenav(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public onScroll($event: any): void {
    this.isTop = $event === 0;
  }
}
