import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Store} from "@ngrx/store";
import {logout} from "../../authentication/store/authentication.actions";
import {IUser} from "../../services/users/users.model";
import {UsersService} from "../../services/users/users.service";
import {ToolbarTitleService} from '../services/toolbar-title.service';

@Component({
  selector: 'core-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  public user: IUser;

  @Input() hideBorder: false;

  constructor(
    public _toolbarTitleService: ToolbarTitleService,
    private _usersService: UsersService,
    private _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._usersService.getLoggedInUser().subscribe((user: IUser) => {
        this.user = user;
      },
    );
  }

  public logout(): void {
    this._store.dispatch(logout());
    window.location.reload();
  }

}
