import {HttpClient} from "@angular/common/http";
import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'urlAddAuth',
})
export class UrlAddAuthPipe implements PipeTransform {

  constructor(
    private _httpClient: HttpClient,
    private _domSanitizer: DomSanitizer,
  ) {
  }

  transform(url: string): Observable<SafeUrl> {
    return this._httpClient
      .get(url, {responseType: 'blob'}).pipe(
        map(val => {
          return this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
        }),
      );
  }
}
