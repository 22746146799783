import {createAction, props} from '@ngrx/store';
import {AuthenticationError, ICredentials} from '../authentication.model';

export const login = createAction(
  '[Authentication] Login',
  props<{ credentials: ICredentials }>(),
);

export const logout = createAction(
  '[Authentication] Logout',
);

export const loginFail = createAction(
  '[Authentication] Login fail',
  props<{ errorCode: AuthenticationError }>(),
);

export const loginSuccess = createAction(
  '[Authentication] Login success',
);

export const decodeToken = createAction(
  '[Authentication] Decode token',
);

