import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from "@auth0/angular-jwt";
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';
import {getToken} from "../jwt-config";
import {AuthenticationService} from '../services/authentication.service';
import {decodeToken} from '../store/authentication.actions';
import {authenticationSelectors} from '../store/authentication.selector';

@Injectable({
  providedIn: 'root',
})
export class LandingGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _store: Store,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    const authToken = getToken();

    if (authToken) {
      this._store.dispatch(decodeToken());
      return this._store.pipe(
        select(authenticationSelectors.isLoggedIn),
        skipWhile((isLoggedIn: boolean) =>
          isLoggedIn === null || isLoggedIn === undefined,
        ),
        map((isLoggedIn: boolean) => {

          if (isLoggedIn) {
            this._router.navigate(['/dashboard']);
          }
          return !isLoggedIn;
        }),
      );

    }

    return true;
  }

}
