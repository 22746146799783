import {environment} from "../../../environments/environment";

const AUTH_TOKEN_SESSION_STORAGE_KEY = 'auth_token';

export function getToken(): string {
  return sessionStorage.getItem(AUTH_TOKEN_SESSION_STORAGE_KEY);
}

export function setToken(token: string) {
  sessionStorage.setItem(AUTH_TOKEN_SESSION_STORAGE_KEY, token);
}

export function removeToken() {
  sessionStorage.removeItem(AUTH_TOKEN_SESSION_STORAGE_KEY);
}

export const jwtConfig: any = {
  tokenGetter: getToken,
  allowedDomains: [environment.domain],
};
