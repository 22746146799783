import {createSelector, MemoizedSelector} from '@ngrx/store';
import {coreSelector} from '../../store/core.selector';
import {ICoreState} from '../../store/core.state';
import {IAuthenticationState} from './authentication.state';

const authenticationSelector: MemoizedSelector<Record<string, unknown>, IAuthenticationState> = createSelector(
  coreSelector,
  (state: ICoreState) => state.authentication,
);

export const authenticationSelectors = {

  isLoggedIn: createSelector(
    authenticationSelector,
    (state: IAuthenticationState) => state.isLoggedIn,
  ),

  errorCode: createSelector(
    authenticationSelector,
    (state: IAuthenticationState) => state.errorCode,
  ),
};
