<div class="container-fluid h-100 p-0">
  <div class="row w-100 h-100 m-0">
    <div class="col-6 h-100 d-flex align-items-center justify-content-center">
      <div class="w-50">
        <h1 class="d-flex align-items-center mb-5">
          <!--          <img src="./assets/logo.svg" alt="logo" [height]="35" class="me-2">-->
          {{ 'core.appName' | translate }}
        </h1>

        <h1>{{ 'core.authentication.login.login' | translate }}</h1>

        <form [formGroup]="loginForm" (ngSubmit)="login()" class="w-100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'core.authentication.login.email' | translate }}</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <br>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'core.authentication.login.password' | translate }}</mat-label>
            <input matInput formControlName="password" type="password">
          </mat-form-field>
          <br>
          <button mat-raised-button color="primary" class="w-100" type="submit" [disabled]="isCheckingCredentials">
            {{ 'core.authentication.login.login' | translate }}
          </button>
        </form>
      </div>
    </div>

    <div class="col-6 right-column-bg h-100">
      <div class="h-50 d-flex justify-content-center align-items-center">
        <img src="./assets/images/landing.svg" alt="logo" class="m-4" height="60%">
        <span class="m-4 w-50 text-center fw-bold">Feature 1 beschreiben</span>
      </div>

      <div class="h-50 d-flex justify-content-center align-items-center">
        <span class="m-4 w-50 text-center fw-bold">Feature 2 bschreiben</span>
        <img src="./assets/images/landing2.svg" alt="logo" class="m-4" height="60%">
      </div>

    </div>
  </div>
</div>
