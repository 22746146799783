import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {getToken} from "../../authentication/jwt-config";
import {IUser} from "./users.model";

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  public getLoggedInUser(): Observable<IUser> {
    const jwt: JwtHelperService = new JwtHelperService();
    const jwtDecoded: any = jwt.decodeToken(getToken());
    const userId: number = Number(jwtDecoded.sub);
    return this.getUserById(userId);
  }

  public getUserById(userId: number): Observable<IUser> {
    return this._httpClient.get<IUser>(`${environment.apiBaseUrl}/users/${userId}`);
  }
}
