import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ContentFrameService} from "../../navigation/services/content-frame.service";
import {AuthenticationError} from '../authentication.model';
import {login} from '../store/authentication.actions';
import {authenticationSelectors} from '../store/authentication.selector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  public isCheckingCredentials: boolean = false;

  public loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private _contentFrameService: ContentFrameService,
    private _store: Store,
    private _snackBar: MatSnackBar,
    private _router: Router,
  ) {
    this._contentFrameService.setFullScreen(true);
  }

  public ngOnInit(): void {
    this._store.select(authenticationSelectors.isLoggedIn)
      .subscribe(
        (isLoggedIn: boolean) => {
          if (isLoggedIn) {
            console.log(isLoggedIn);
            this._router.navigate(['/dashboard']);
          }
        },
      );

    this._store.select(authenticationSelectors.errorCode)
      .subscribe(
        (errorCode: AuthenticationError) => {
          if (errorCode) {
            this.isCheckingCredentials = false;
            this._snackBar.open(errorCode);
          }
        },
      );
  }

  public ngOnDestroy(): void {
    this._contentFrameService.setFullScreen(false);
  }

  public login(): void {
    this.isCheckingCredentials = true;
    this._store.dispatch(login(({credentials: this.loginForm.value})));
  }

}
