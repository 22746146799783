import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ContentFrameService {

  public isFullScreen: Subject<boolean> = new BehaviorSubject(false);

  constructor() {
  }

  public setFullScreen(value: boolean) {
    this.isFullScreen.next(value);
  }
}
