import {ElementRef, Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolbarTitleService implements OnDestroy {

  private frameTitle: string;

  private _featureControls: TemplateRef<any>;

  private subscriptionTranslate$: Subscription = new Subscription();

  constructor(
    private _translateService: TranslateService,
    private _title: Title,
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptionTranslate$) {
      this.subscriptionTranslate$.unsubscribe();
    }
  }

  public get featureControls(): TemplateRef<any> {
    return this._featureControls;
  }

  public set featureControls(value: TemplateRef<any>) {
    this._featureControls = value;
  }

  public resetFeatureControls(): void {
    this._featureControls = undefined;
  }

  public get title(): string {
    return this.frameTitle;
  }

  public set titleTranslationKey(value: string) {
    this.subscriptionTranslate$ = this._translateService.get(value).pipe(first()).subscribe(
      (frameTitle: string) => {
        this.frameTitle = frameTitle;
        this._title.setTitle(this.frameTitle);
      },
    );
  }

}
