import {LayoutModule} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtModule} from "@auth0/angular-jwt";
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule, TranslateModuleConfig} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {jwtConfig} from "./core/authentication/jwt-config";
import {CoreModule} from './core/core.module';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

export function HttpTranslateLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  TimeAgo.addDefaultLocale(en)
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

const translateConfig: TranslateModuleConfig = {
  defaultLanguage: 'en_US',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpTranslateLoaderFactory,
    deps: [HttpClient],
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    EffectsModule.forRoot(),
    JwtModule.forRoot({
      config: jwtConfig,
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    TranslateModule.forRoot(translateConfig),
    HttpClientModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
