<mat-toolbar [ngClass]="{'mat-elevation-z4': !hideBorder}">
  <mat-toolbar-row>
    <span class="me-3">{{_toolbarTitleService.title}}</span>

    <ng-template *ngTemplateOutlet="_toolbarTitleService.featureControls">
    </ng-template>

    <span class="spacer"></span>

    <div *ngIf="user" [matMenuTriggerFor]="menu" class="profile">
      <span class="user-name">{{ user?.firstName + ' ' + user?.lastName }}</span>
      <img class="profile-picture" *ngIf="user?.profilePictureUrl" [src]="user?.profilePictureUrl | urlAddAuth | async"  alt="profile-picture"/>
    </div>

    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>

  </mat-toolbar-row>
</mat-toolbar>
