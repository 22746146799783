import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {IAuthenticationToken} from '../authentication.model';
import {getToken, setToken} from "../jwt-config";
import {AuthenticationService} from '../services/authentication.service';
import {decodeToken, login, loginFail, loginSuccess} from './authentication.actions';

@Injectable()
export class AuthenticationEffects {

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
  ) {
  }

  loginActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login.type),
      exhaustMap((action: any) => {
        return this.authenticationService.login(action.credentials).pipe(
          map((authToken: IAuthenticationToken) => {
            setToken(authToken.bearer);
            return decodeToken();
          }),
          catchError(error => {
            return of(loginFail({errorCode: error.error.errorCode}));
          }),
        );
      }),
    ));

  decodeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(decodeToken.type),
      exhaustMap((action: any) => {

        const jwt: JwtHelperService = new JwtHelperService();

        const token: string = getToken();

        const jwtDecoded = jwt.decodeToken(token);

        if (jwtDecoded === undefined || jwt.isTokenExpired(token)) {
          return of({type: loginFail.type});
        }

        return of({type: loginSuccess.type});
      }),
    ),
  );

}
