import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingGuard} from './authentication/guards/landing.guard';
import {LoginComponent} from './authentication/login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LandingGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {
}
